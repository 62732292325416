import(/* webpackMode: "eager" */ "C:\\Users\\Godspower Mukoro\\Desktop\\Apps-customer\\dessysatic_store\\app\\globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\Godspower Mukoro\\Desktop\\Apps-customer\\dessysatic_store\\components\\Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\Godspower Mukoro\\Desktop\\Apps-customer\\dessysatic_store\\components\\Navbartt.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\Godspower Mukoro\\Desktop\\Apps-customer\\dessysatic_store\\components\\NewsletterSubscription.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CurrencyProvider"] */ "C:\\Users\\Godspower Mukoro\\Desktop\\Apps-customer\\dessysatic_store\\lib\\context\\currencyContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ClientClerkProvider"] */ "C:\\Users\\Godspower Mukoro\\Desktop\\Apps-customer\\dessysatic_store\\node_modules\\@clerk\\nextjs\\dist\\esm\\app-router\\client\\ClerkProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["AuthenticateWithRedirectCallback","ClerkLoaded","ClerkLoading","MultisessionAppSupport","RedirectToCreateOrganization","RedirectToOrganizationProfile","RedirectToSignIn","RedirectToSignUp","RedirectToUserProfile"] */ "C:\\Users\\Godspower Mukoro\\Desktop\\Apps-customer\\dessysatic_store\\node_modules\\@clerk\\nextjs\\dist\\esm\\client-boundary\\controlComponents.js");
;
import(/* webpackMode: "eager", webpackExports: ["EmailLinkErrorCode","MagicLinkErrorCode","WithClerk","WithSession","WithUser","isClerkAPIResponseError","isEmailLinkError","isKnownError","isMagicLinkError","isMetamaskError","useAuth","useClerk","useEmailLink","useMagicLink","useOrganization","useOrganizationList","useOrganizations","useSession","useSessionList","useSignIn","useSignUp","useUser","withClerk","withSession","withUser"] */ "C:\\Users\\Godspower Mukoro\\Desktop\\Apps-customer\\dessysatic_store\\node_modules\\@clerk\\nextjs\\dist\\esm\\client-boundary\\hooks.js");
;
import(/* webpackMode: "eager", webpackExports: ["CreateOrganization","GoogleOneTap","OrganizationList","OrganizationProfile","OrganizationSwitcher","SignIn","SignInButton","SignInWithMetamaskButton","SignOutButton","SignUp","SignUpButton","UserButton","UserProfile"] */ "C:\\Users\\Godspower Mukoro\\Desktop\\Apps-customer\\dessysatic_store\\node_modules\\@clerk\\nextjs\\dist\\esm\\client-boundary\\uiComponents.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\Godspower Mukoro\\Desktop\\Apps-customer\\dessysatic_store\\node_modules\\next\\font\\google\\target.css?{\"path\":\"app\\\\(root)\\\\layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "C:\\Users\\Godspower Mukoro\\Desktop\\Apps-customer\\dessysatic_store\\node_modules\\react-hot-toast\\dist\\index.mjs");
